<template>
    <section class="new_css">
        <el-row class="new_header">
            <el-col v-if="buttonList.length > 0"
                    :span="24"
                    class="organizetoolbar"
                    style="padding-bottom: 0px">
                <el-form :inline="true"
                         ref="selectForm"
                         :model="selectForm"
                         class="select_form">
                    <el-form-item label="专利名称:">
                        <el-input v-model="selectForm.patentName"
                                  placeholder="专利名称"></el-input>
                    </el-form-item>
                    <el-form-item label="发明人:">
                        <el-input v-model="selectForm.person"
                                  placeholder="发明人"></el-input>
                    </el-form-item>
                    <el-form-item label="专利类型:">
                        <el-select v-model="selectForm.type"
                                   clearable
                                   placeholder="请选择"
                                   style="“width: 100%”">
                            <el-option label="实用新型" value="实用新型"></el-option>
                            <el-option label="发明 " value="发明"></el-option>
                            <el-option label="外观设计 " value="外观设计"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专利法律状态:">
                        <el-select v-model="selectForm.state"
                                   clearable
                                   placeholder="请选择"
                                   style="“width: 100%”">
                            <el-option label="有效" value="有效"></el-option>
                            <el-option label="失效 " value="失效"></el-option>
                        </el-select>
                    </el-form-item>
                    <toolbar :buttonList="buttonList"
                             @callFunction="callFunction"
                             :buttonListmsg="buttonListmsg"></toolbar>
                </el-form>
            </el-col>
        </el-row>
        <div class="calc-height">
            <!--列表-->
            <el-table :data="users"
                      highlight-current-row
                      height="100%"
                      @current-change="selectCurrentRow"
                      @row-dblclick="ondblclick"
                      class="new_table">
                <el-table-column label="序号" prop="RowIndex" width="50" align="center"></el-table-column>
                <el-table-column label="专利类型" prop="Type"></el-table-column>
                <el-table-column label="专利名称" prop="PatentName"> </el-table-column>
                <el-table-column label="专利号" prop="PatentNumber"></el-table-column>
                <el-table-column label="发明人" prop="UserName"></el-table-column>
                <el-table-column label="申请人" prop="Applicant"></el-table-column>
                <el-table-column label="专利法律状态" prop="State"></el-table-column>
                <el-table-column label="授权公告号" prop="AnnouncementNumber"></el-table-column>
                <el-table-column label="证书编号" prop="CertificateNumber"></el-table-column>
                <el-table-column label="申请日期" prop="ApplicationDate" :formatter="formatStartTime"></el-table-column>
                <el-table-column label="授权日期" prop="AuthorizationDate" :formatter="formatStartTime"></el-table-column>
                <el-table-column label="有效日期" prop="EffectiveDate" :formatter="formatStartTime"></el-table-column>
                <el-table-column label="获取方式" prop="AcquireWay"></el-table-column>
                <el-table-column label="申请年度"
                                 show-overflow-tooltip
                                 prop="ApplicationYear"></el-table-column>
                <el-table-column label="授权年度"
                                 show-overflow-tooltip
                                 prop="AnnouncementYear"></el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-size="pageSize"
                       layout="prev, pager, next, jumper ,total"
                       :page-count="total"
                       :total="totaldata">
        </el-pagination>
        <el-dialog :title="operation ? '添加' : '编辑'"
                   :visible.sync="SakaryVisible"
                   v-model="SakaryVisible"
                   :close-on-click-modal="false">
            <el-form ref="SakaryForm"
                     :model="SakaryForm"
                     class="demo-form-inline"
                     label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="申请人:"
                                      prop="Applicant"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-input type="text" v-model="SakaryForm.Applicant"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="专利名称:"
                                      prop="PatentName"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-input type="text" v-model="SakaryForm.PatentName"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="专利号:"
                                      prop="PatentNumber"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-input type="text" v-model="SakaryForm.PatentNumber">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="证书编号:"
                                      prop="CertificateNumber"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-input type="text"
                                      v-model="SakaryForm.CertificateNumber"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="授权公告号:"
                                      prop="AnnouncementNumber"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-input type="text" v-model="SakaryForm.AnnouncementNumber">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="专利类型:"
                                      prop="Type"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-select v-model="SakaryForm.Type"
                                       placeholder="请选择"
                                       style="width: 100%">
                                <el-option label="实用新型" value="实用新型"></el-option>
                                <el-option label="发明 " value="发明"></el-option>
                                <el-option label="外观设计 " value="外观设计"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="专利法律状态:"
                                      prop="State"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-select v-model="SakaryForm.State"
                                       placeholder="请选择"
                                       style="width: 100%">
                                <el-option label="有效" value="有效"></el-option>
                                <el-option label="失效 " value="失效"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="申请日期:"
                                      prop="ApplicationDate"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-date-picker type="date"
                                            v-model="SakaryForm.ApplicationDate"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期"
                                            @change="ApplicationDateChange($event)"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="授权⽇期:"
                                      prop="AuthorizationDate"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-date-picker type="date"
                                            v-model="SakaryForm.AuthorizationDate"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期"
                                            @change="AuthorizationDateChange($event)"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="有效⽇期:"
                                      prop="EffectiveDate"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-date-picker type="date"
                                            v-model="SakaryForm.EffectiveDate"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="获取⽅式:"
                                      prop="AcquireWay"
                                      :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]">
                            <el-select v-model="SakaryForm.AcquireWay"
                                       placeholder="请选择"
                                       style="width: 100%">
                                <el-option label="自主研发" value="自主研发"></el-option>
                                <el-option label="转让" value="转让"></el-option>
                                <el-option label="合作研发 " value="合作研发"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="申请年度:"
                                          prop="ApplicationYear"
                                          :rules="[
                  { required: true, message: '不能为空', trigger: 'blur' },
                ]">
                                <el-input type="text"
                                          v-model="SakaryForm.ApplicationYear"
                                          disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="授权年度:"
                                          prop="AnnouncementYear"
                                          :rules="[
                  { required: true, message: '不能为空', trigger: 'blur' },
                ]">
                                <el-input type="text"
                                          v-model="SakaryForm.AnnouncementYear"
                                          disabled></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="应用产品">
                                <el-input type="textarea"
                                          v-model="SakaryForm.Product"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-col :span="24">
                        <el-form-item label="简要说明">
                            <el-input type="textarea" v-model="SakaryForm.Explain"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注">
                            <el-input type="textarea" v-model="SakaryForm.Remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="发明人" label-width="120px">
                            <el-button @click="chooseUser()">选择发明人</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="SakaryForm.Persons"
                              style="width: 100%"
                              class="bg-color">
                        <el-table-column prop="UserName" label="姓名"></el-table-column>
                        <el-table-column label="操作" width="150">
                            <template scope="scope">
                                <el-button size="small"
                                           v-on:click="PersonsDelete(scope.row)"
                                           type="text">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <upload-files :files="SakaryForm.Files"
                              :key="key"
                              action="/cyl/ftp/ftp/upload"
                              :limit="20"
                              @fun="dealFiles"
                              :IsDisabled="operation1"
                              :IsDel="operation1"></upload-files>
                <el-row v-if="operation1">
                    <el-col :span="24" style="text-align: center">

                        <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>

                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-dialog title="选择人员"
                   :visible.sync="addUserVisible"
                   v-model="addUserVisible"
                   width="75%"
                   center
                   append-to-body>
            <UserChoose :data="choosedusers"
                        :all="true"
                        :single="false"
                        @callback="usercallFunction"></UserChoose>
        </el-dialog>
        <patentModal ref="patentModal"></patentModal>
    </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import UserChoosejob from "../../components/UserChoosejob";
import UploadFiles from "../../components/UploadFiles";
import {
  PatentGetInfoPageList,
  PatentAddInfo,
  PatentUpdateInfo,
  PatentGetInfoList,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/newToolbar";
    import patentModal from "../componentModals/patentModal"    // 专利

export default {
        components: { Toolbar, UserChoose, UserChoosejob, UploadFiles,patentModal },
  data() {
    return {
      loading: true,
      searchVal: "",
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      choosedusers: [],
      Intochoosedusers: [],
      operation: false, // true:新增, false:编辑
      operation1: true,
      addUserVisible: false,
      IntoUserVisible: false,
      buttonList: [],
      SakaryForm: {
        PatentName: null,
        PatentNumber: null,
        Type: null,
        State: null,
        AnnouncementNumber: null,
        CertificateNumber: null,
        ApplicationDate: null,
        AuthorizationDate: null,
        EffectiveDate: null,
        Applicant: null,
        AcquireWay: null,
        Explain: null,
        Product: null,
        ApplicationYear: null,
        AnnouncementYear: null,
        Remark: null,
        Persons: [],
        Files: [],
      },
      selectForm: {
        patentName: "",
        type: "",
        state: "",
        person: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    PersonsDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.SakaryForm.Persons.splice(this.SakaryForm.Persons.indexOf(row), 1);
      });
    },
    usercallFunction(newdata) {
      for (var i = 0; i < newdata.length; i++) {
        this.SakaryForm.Persons.push({
          UserName: newdata[i].UserNameClaim,
          UserId: newdata[i].Id,
        });
      }

      //  this.SakaryForm.TalkPersons = userStr;
      this.addUserVisible = false;
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    chooseUser() {
      this.addUserVisible = true;
    },

    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    ApplicationDateChange(o) {
      this.SakaryForm.ApplicationYear = o.slice(0, 4);
    },
    AuthorizationDateChange(o) {
      this.SakaryForm.AnnouncementYear = o.slice(0, 4);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        patentName: this.selectForm.patentName,
        type: this.selectForm.type,
        state: this.selectForm.state,
        person: this.selectForm.person,
      };

      PatentGetInfoPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      PatentGetInfoList({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      PatentGetInfoList({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = false;
    },
    ondblclick(row, column, cell, event) {
        this.$refs.patentModal.onShowDlg(row.Id);
    },
    handleAdd() {
      (this.SakaryForm = {
        PatentName: null,
        PatentNumber: null,
        Type: null,
        State: null,
        AnnouncementNumber: null,
        CertificateNumber: null,
        ApplicationDate: null,
        AuthorizationDate: null,
        EffectiveDate: null,
        Applicant: null,
        AcquireWay: null,
        Explain: null,
        Product: null,
        ApplicationYear: null,
        AnnouncementYear: null,
        Remark: null,
        Persons: [],
        Files: [],
      }),
        (this.key += 1);
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            if (this.operation) {
              //新增
              PatentAddInfo(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
              PatentUpdateInfo(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData(false);
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    dealFiles(data) {
      console.log(data.backData);
      this.SakaryForm.Files = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

